import React, { useState,useEffect } from 'react';
import Softbanner from '../../components/softbanner/Softbanner';
import '../contact/contact.css'
import { Col, Container, Row } from 'react-bootstrap';
import '../demo/demo.css';


import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import PrimaryBtn from '../../components/primaryBtn/PrimaryBtn';
import ReactDOM from 'react-dom';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import { API_URL } from '../../App';


import Modal from 'react-modal';





const Demo = () => {



  const [selectedDate, setSelectedDate] = useState(null);
  const [activeDates, setActiveDates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
 



  useEffect(() => {
     fetchActiveDates();
    
  }, []);


  const fetchActiveDates = async () => {
    try {
      // Fetch active dates from the API
      const response = await fetch(API_URL+'fetchDatesExcludingHolidays');
      if (!response.ok) {
        throw new Error('Failed to fetch active dates');
      }
      const data = await response.json();
      setActiveDates(data);
    } catch (error) {
      console.error('Error fetching active dates:', error);
    }
  };

  const subtractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };



  const isDateActive = date => {
    // const formattedDate = date.toISOString().split('T')[0];

    const daysToSubtract = 1;
const subtractedDate = subtractDays(date, daysToSubtract);
     const formattedDate = date.toISOString().split('T')[0];
    const formattedDate1 = subtractedDate.toISOString().split('T')[0];
    console.log(activeDates);
     return activeDates.includes(formattedDate1);
   
  };






  const handleDateChange = date => {
    setSelectedDate(date);
  };
 


    const [formData, setFormData] = useState({
        demo_name: '',
        demo_email: '',
        demo_phone: '',
        demo_type: '',
       
        demo_start_time: '',
       
       
        // Add more fields as needed
      });
    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };


      const getFmDate = (selectedDate) => {
        const date = new Date(selectedDate);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 to adjust month index
        const year = date.getFullYear();
        
        // Padding day and month with leading zeros if needed
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        
       // const formattedDate = formattedDay + '/' + formattedMonth + '/' + year;

        const formattedDate = formattedMonth + '/' + formattedDay + '/' + year;
        //alert(formattedDate);
        return formattedDate;
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
       

        const selectedDateFm = getFmDate(selectedDate);
        const updatedFormData = {
          ...formData,
          demo_date: selectedDateFm // Example of appending additional data
        };
        
        console.log(updatedFormData);
      
        const errors = validateForm(updatedFormData);

        if (Object.keys(errors).length === 0) {
          try {
            // Data is valid, make a POST request to your API
            setLoading(true); // Start loading
            setModalIsOpen(true);
            const response = await axios.post(API_URL+'submit-free-demo', updatedFormData);
            console.log('Data inserted:', response.data);
            // Display success toast message
            setLoading(false); // Stop loading
            setModalIsOpen(false);
            toast.success("Record inserted successfully", '', {timeOut: 4000});
            // Optionally, reset form after successful submission
            setFormData({
                demo_name: '',
                demo_email: '',
                demo_phone: '',
                demo_type: '',
               
                demo_start_time: '',
               
              // Add more fields as needed
            });
            // Reset errors
          
            setSelectedDate(null);
            setErrors({});
          } catch (error) {
            console.error('Error inserting data:', error);
            toast.error('Error inserting data. Please try again.','', {timeOut: 4000});
          }
        } else {
          // There are validation errors, display them to the user
          setErrors(errors);
        }
      };
    
      const validateForm = (data) => {
        let errors = {};
        if (!data.demo_name.trim()) {
          errors.demo_name = 'Name is required';
        }

     

          if (!data.demo_email.trim()) {
            errors.demo_email = 'Email is required';
          } else {
            // Check email format using regular expression
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(data.demo_email)) {
              errors.demo_email = 'Invalid email address';
            }
          }

          if (!data.demo_phone.trim()) {
            errors.demo_phone = 'Phone is required';
          }

          
          if (!data.demo_type.trim()) {
            errors.demo_type = 'Type is required';
          }
         
          if (!data.demo_date || data.demo_date==='01/01/1970') {
            errors.demo_date = 'Date is required';
          }

          if (!data.demo_start_time.trim()) {
            errors.demo_start_time = 'Starttime is required';
          }

          

        // You can add more validation rules for other fields here
        return errors;
      };
    return (
        <>
       <Softbanner pageName="Free Demo"/>


       <section className='contact_form_wrapper'>
        <Container>
            <Row>
               

                <Col sm={12}>
                    <div className="form_outer">
                        <Form onSubmit={handleSubmit}>
                            <b className='formtitle'>Let's Talk</b>
                            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                shouldCloseOnOverlayClick={false} // Prevent close on overlay click
                style={customStyles}
                contentLabel="Loading Modal"
            >
                <h2>Loading...</h2>
            </Modal>
                            <Row>
                                <Col sm={6}>
                                <ToastContainer />
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="demo_name" value={formData.demo_name} onChange={handleChange} placeholder="Full Name" />
                                        {errors.demo_name && <span className='ErrMsg'>{errors.demo_name}</span>}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="text" name="demo_email" value={formData.demo_email} onChange={handleChange} placeholder="Email ID" />
                                        {errors.demo_email && <span className='ErrMsg'>{errors.demo_email}</span>}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control type="text" name="demo_phone" value={formData.demo_phone} onChange={handleChange}   placeholder="10 Digit Mobile No" />
                                        {errors.demo_phone && <span className='ErrMsg'>{errors.demo_phone}</span>}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>Type</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-control' name="demo_type" value={formData.demo_type} onChange={handleChange} >
                                        <option>Select Type</option>
                                        <option value="Pre-recorded">Pre-recorded</option>
                                        <option value="Online">Online</option>
                                        <option value="Physical">Physical</option>
                                    </Form.Select>
                                    {errors.demo_type && <span className='ErrMsg'>{errors.demo_type}</span>}
                                    </Form.Group>
                                </Col>
                            

                                <Col sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label>Select a Date</Form.Label>
   
<DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        minDate={new Date()}
        filterDate={isDateActive}
        className="form-control"
      />
      {errors.demo_date && <span className='ErrMsg'>{errors.demo_date}</span>}
    </Form.Group> 

                                  
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Start Time</Form.Label>

                                    <Form.Control type="time" name="demo_start_time" value={formData.demo_start_time} onChange={handleChange}    />

                                        {errors.demo_start_time && <span className='ErrMsg'>{errors.demo_start_time}</span>}
                                    </Form.Group>
                                </Col>

                               

                            
                                <Col sm={12}>
                                <button type="submit" className="btn btn-primary">Submit</button>
                                    {/* <PrimaryBtn btnText="submit"  type="submit" refLink="#"/> */}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
       </section>
        </>
    );
}

export default Demo;
