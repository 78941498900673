import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaPhone } from "react-icons/fa6";
import { FaMessage } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { useState } from 'react';
import '../Topbar/topbar.css'

const Topbar = () => {
   

      return (
          
          <>
        <section className='topbar'>
            <Container>
                <Row style={{alignItems: 'center'}}>
                    <Col sm={8} lg={8} xl={6}>
                    <ul className="topbar_left">
                        <li><a href="tel:+91 112 627 2275"><span><FaPhone /></span> +91 112 627 2275</a></li>
                        <li><a href="mailto:info@cortexsolutions.in"><span><FaMessage /></span> info@cortexsolutions.in</a></li>
                        <li><div><span><FaLocationDot /></span> B-248, LGF, New Delhi – 110019, India</div></li>
                    </ul>
                    </Col>
                    <Col sm={4} lg={4} xl={6}>
                    <ul className="top_right">
                        <li><p>Share:</p></li>
                        <li><a href=""><FaFacebookF /></a></li>
                        <li><a href=""><FaLinkedinIn /></a></li>
                    </ul>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default Topbar;
