import React,{ useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaQuoteRight } from "react-icons/fa6";
import client1 from '../../images/client1.png';
import axios from 'axios';
import { API_URL } from '../../App';

const TestimonialSlide = () => {
    const options = {
        items: 1,
        nav: true,
        rewind: true,
        margin: 0,
        loop: true,
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: true,
        dotData: true
      };
      const [Testimonialdata, setTestimonialdata] = useState(null);
      useEffect(() => {
        
        const fetchTestimonialData = async () => {
          try {
            const response = await axios.get(API_URL+'getTestimonial');
           // console.log('Response Data:', response.data); // Logging response.data
            setTestimonialdata(response.data);
           
          } catch (error) {
            console.error('Error fetching data:', error);
            
          }
        };
        fetchTestimonialData();
        //console.log(ExpertTemdata);

       //Ends
      }, []);
    return (
        <>
        <OwlCarousel className='owl-theme' {...options}>
        { Testimonialdata && Testimonialdata.map((item, index) => (
            
            <div class='item'>
                <div className="testimonial_area">
                    <p>
                        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda magnam totam sequi, molestias excepturi possimus in omnis animi earum dolore alias dignissimos repellendus veniam nesciunt voluptatem cupiditate cum neque quasi. 
                       {item.testimonial_description}*/} 
                        </p>

                        <div dangerouslySetInnerHTML={{ __html: item.testimonial_description }} />

                    <div className="client_details">
                        <div className="client_img"><img src={item.testimonial_image_path} alt="" /></div>
                        <div className="client_info">
                            <b>{item.testimonial_name}</b>
                            <small>{item.testimonial_type}</small>
                        </div>
                    </div>
                    <div className="testimonial_quote"><FaQuoteRight /></div>
                </div>
            </div>
            ))} 
            {/* <div class='item'>
                <div className="testimonial_area">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda magnam totam sequi, molestias excepturi possimus in omnis animi earum dolore alias dignissimos repellendus veniam nesciunt voluptatem cupiditate cum neque quasi.</p>

                    <div className="client_details">
                        <div className="client_img"><img src={client1} alt="" /></div>
                        <div className="client_info">
                            <b>Mr. Angsuman Shil</b>
                            <small>Happy Client</small>
                        </div>
                    </div>
                    <div className="testimonial_quote"><FaQuoteRight /></div>
                </div>
            </div>
            <div class='item'>
                <div className="testimonial_area">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda magnam totam sequi, molestias excepturi possimus in omnis animi earum dolore alias dignissimos repellendus veniam nesciunt voluptatem cupiditate cum neque quasi.</p>

                    <div className="client_details">
                        <div className="client_img"><img src={client1} alt="" /></div>
                        <div className="client_info">
                            <b>Mr. Angsuman Shil</b>
                            <small>Happy Client</small>
                        </div>
                    </div>
                    <div className="testimonial_quote"><FaQuoteRight /></div>
                </div>
            </div>
            <div class='item'>
                <div className="testimonial_area">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda magnam totam sequi, molestias excepturi possimus in omnis animi earum dolore alias dignissimos repellendus veniam nesciunt voluptatem cupiditate cum neque quasi.</p>

                    <div className="client_details">
                        <div className="client_img"><img src={client1} alt="" /></div>
                        <div className="client_info">
                            <b>Mr. Angsuman Shil</b>
                            <small>Happy Client</small>
                        </div>
                    </div>
                    <div className="testimonial_quote"><FaQuoteRight /></div>
                </div>
            </div>
            <div class='item'>
                <div className="testimonial_area">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda magnam totam sequi, molestias excepturi possimus in omnis animi earum dolore alias dignissimos repellendus veniam nesciunt voluptatem cupiditate cum neque quasi.</p>

                    <div className="client_details">
                        <div className="client_img"><img src={client1} alt="" /></div>
                        <div className="client_info">
                            <b>Mr. Angsuman Shil</b>
                            <small>Happy Client</small>
                        </div>
                    </div>
                    <div className="testimonial_quote"><FaQuoteRight /></div>
                </div>
            </div> */}
        </OwlCarousel>
        </>
    );
}

export default TestimonialSlide;
