import React, { useEffect,useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/logo.png';
import "../Headersec/header.css";
import { FaBars } from "react-icons/fa6";
import axios from 'axios';
import { API_URL } from '../../App';


const Header = () => {
    const [Cmsdata, setCmsdata] = useState([]);
    
    useEffect(() => {
        
        const fetchCmsData = async () => {
            
              const response = await axios.get(API_URL+'getCms');
              console.log('Response Data:', response.data); // Logging response.data
              setCmsdata(response.data);
             
    
    
          
          };

          fetchCmsData();
        
        
       
        
      }, []);

    

     
      
     
     // const firstElement = Cmsdata.length > 0 ? Cmsdata[0] : null;

      console.log(Cmsdata);


    return (
        <>
            <header className="header_wrapper">
                <Navbar expand="lg" className="header_area">
                    <Container>
                        <div className="navber_inner">
                        <Link to={"/home"}> <img src={logo} alt="" /> </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" ><FaBars /></Navbar.Toggle>
                        
                        <Navbar.Collapse id="basic-navbar-nav">
                        {/* <Nav className="mr-auto">
                            <Link className='navlink active' to={"/home"}>Home</Link>
                            <Link className='navlink' to={"/about"}>About</Link>
                            <Link className='navlink' to={"/team"}>Team</Link>
                            <Link className='navlink' to={"/about"}>Blog</Link>
                            <Link className='navlink' to={"/products"}>Products</Link>
                            <Link className='navlink' to={"/contact"}>Contact Us</Link>
                        </Nav> */}
                        <Nav className="mr-auto">
                        <NavLink></NavLink>
                            <NavLink className={"navlink"}  to={"/home"}>Home</NavLink>
                            <NavLink className={"navlink"}  to={"/about"}>About</NavLink>
                            <NavLink className={"navlink"}  to={"/team"}>Team</NavLink>
                            {/* <NavLink className={"navlink"}  to={"/about"}>Blog</NavLink> */}
                            <NavLink className={"navlink"}  to={"/products"}>Products</NavLink>
                            <NavLink className={"navlink"}  to={"/services"}>Services</NavLink>
                            <NavLink className={"navlink"}  to={"/clients"}>Clients</NavLink>
                            <NavLink className={"navlink"}  to={"/contact"}>Contact Us</NavLink>
                            <NavLink className={"navlink"}  to={"/free-demo"}>Free Demo</NavLink>
                            {Cmsdata.map((item, index) => (
                            <NavLink className={"navlink"}  to={item.cms_slug}>{item.cms_name}</NavLink>
                            ))}
                       
                            
                        </Nav>
                        </Navbar.Collapse>
                        </div>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}

export default Header;
