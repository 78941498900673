import React,{ useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaHeart } from "react-icons/fa6";
import prj1 from '../../images/projects/projects-img1.png';
import prj2 from '../../images/projects/projects-img2.png';
import prj3 from '../../images/projects/projects-img3.png';
import prj4 from '../../images/projects/projects-img4.png';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../App';
import { Link } from 'react-router-dom';


const LatestSlide = () => {
    const options = {
        nav: true,
        rewind: true,
        margin: 30,
        loop: true,
        autoplay: false,
        slideBy: 4,
        dots: false,
        dotsEach: true,
        dotData: true,
        responsive:{
            0:{
                items:1
            },
            576:{
                items:2
            },
            1000:{
                items:4
            }
        }
      };

      const [Projectdata, setProjectdata] = useState(null);
      useEffect(() => {
        
        const fetchProjectData = async () => {
          try {
            const response = await axios.get(API_URL+'getProject');
            //console.log('Response Data:', response.data); // Logging response.data
            setProjectdata(response.data);
           
          } catch (error) {
            console.error('Error fetching data:', error);
            
          }
        };
        fetchProjectData();
        //console.log(ExpertTemdata);

       //Ends
      }, []);

    return (
        <>
        <Container fluid>
        <OwlCarousel className='owl-theme' {...options}>
            {/* <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj2} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div> */}
           

          
           {/* <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj3} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div> */}
          
          
          
          { Projectdata && Projectdata.map((item, index) => (
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                    <a href={'/project-details/'+item.project_slug}>
                        <img src={item.project_image_path} alt="projects-img"/>
                        </a>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href={'/project-details/'+item.project_slug}>{item.project_name}</a></h3>
                        {/* <span class="projects-text">Lorence Projects</span> */}
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            ))} 
            {/* <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div> */}


            {/* <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj1} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div> */}
           {/* <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj3} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="projects-single-box">
                    <div class="projects-thumb">
                        <img src={prj4} alt="projects-img"/>
                    </div>
                    <div class="projects-content">
                        <h3 class="projects-title"><a href="#">General Construction</a></h3>
                        <span class="projects-text">Lorence Projects</span>
                    </div>
                    <div class="projects-socail-icon">
                        <ul>
                            <li><FaHeart /></li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </OwlCarousel>
        </Container>
        </>
    );
}

export default LatestSlide;
