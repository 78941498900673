import React from 'react';
import Softbanner from '../../components/softbanner/Softbanner';
import '../About/about.css'
import { Col, Container, Row } from 'react-bootstrap';
import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState,useEffect } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import aboutbig from '../../images/imageabout.jpg';
import aboutbig1 from '../../images/aboutbig-1.jpg';
import faq1 from '../../images/faq-image-one.png';
import faq2 from '../../images/faq-image-two.png';

import { FaThumbsUp } from "react-icons/fa6";
import { FaTrophy } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa6";
import { FaHandHoldingHand } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../App';

const About = () => {
    const [counter, setcounter] = useState(false)
    const location = useLocation();
  const lastUriPart = location.pathname.split('/').pop();


  const [Cmsdata, setCmsdata] = useState([]);
  useEffect(() => {

    const fetchCmsData = async () => {
      try {
        const response = await axios.get(API_URL+'getSingleCms/'+lastUriPart);
        console.log('Response Data:', response.data); // Logging response.data
        setCmsdata(response.data);
       
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };
      
      
      fetchCmsData();
     

     //Ends
    }, []);

    const firstElement = Cmsdata.length > 0 ? Cmsdata[0] : null;
    
    var htmlString ='';

    // if()

    if(firstElement)
    {
        htmlString =firstElement.cms_description;

    }

    console.log(firstElement);

    return (
        <>
            <Softbanner pageName={firstElement && (firstElement.cms_name)}/>
              
            <section className='aboutpage' >
            <Row style={{"padding": "35px"}}>
            <Col sm={12} md={12}>
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
            </Col>
            </Row>
            </section>

                    {/* ====numbers sec start==== */}

        {/* ====numbers sec close==== */}

                {/* ===FAQ section start==== */}
              
        {/* ===FAQ section start==== */}
        </>
    );
}

export default About;
