import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";


const contactData = [
    {
        contactIcon : <FaPhoneAlt />,
        contactTitle : "CALL US 24/7",
        contactPara1 : "+1 (194)-123-456-789",
        contactPara2 : "+1 (194)-123-456-790"
    },
    {
        contactIcon : <FaMapMarkerAlt />,
        contactTitle : "OUR LOCATION",
        contactPara1 : "4517 Washington, Manchester",
        contactPara2 : "Kentucky 39495"
    },
    {
        contactIcon : <FaPhoneAlt />,
        contactTitle : "MAIL US 24/7",
        contactPara1 : "admin@pbminfotech.com",
        contactPara2 : "hello@pbmadmin.com"
    }

]

export default contactData;