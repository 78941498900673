import React, { useState, useRef ,useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { API_URL } from '../../App';

const Brandslider = () => {
    const options = {
        nav: false,
        rewind: true,
        margin: 0,
        loop: true,
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: true,
        dotData: true,
        responsive:{
            0:{
                items:2
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
      };

      const [Clientdata, setClientdata] = useState(null);
      useEffect(() => {
        
        const fetchClientData = async () => {
          try {
            const response = await axios.get(API_URL+'getClient');
           // console.log('Response Data:', response.data); // Logging response.data
           setClientdata(response.data);
           
          } catch (error) {
            console.error('Error fetching data:', error);
            
          }
        };
        fetchClientData();
        //console.log(ExpertTemdata);

       //Ends
      }, []);

    
 
   

    return (
        <>
                    <OwlCarousel className='owl-theme' {...options}>
                    { Clientdata && Clientdata.map((item, index) => (
                       <div className='brand_item'>
                       <img src={item.client_image_path} alt="" />
                   </div>
                    ))} 
                
                        {/* <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/bsi.png?im=128" alt="" />
                        </div>
                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/indian_roads_congress.png?im=174" alt="" />
                        </div>
                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/asnt.png?im=74" alt="" />
                        </div>
                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/NABL.png?im=189" alt="" />
                        </div>

                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/NABL.png?im=189" alt="" />
                        </div>
                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/NABL.png?im=189" alt="" />
                        </div>
                        <div className='brand_item'>
                            <img src="https://www.cortexsolutions.in/images/code/NABL.png?im=189" alt="" />
                        </div> */}
                        
                    </OwlCarousel>
        </>
    );
}

export default Brandslider;
