import React from 'react';
import Softbanner from '../../components/softbanner/Softbanner';
import '../About/about.css'
import { Col, Container, Row } from 'react-bootstrap';
import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import aboutbig from '../../images/imageabout.jpg';
import aboutbig1 from '../../images/aboutbig-1.jpg';
import faq1 from '../../images/faq-image-one.png';
import faq2 from '../../images/faq-image-two.png';

import { FaThumbsUp } from "react-icons/fa6";
import { FaTrophy } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa6";
import { FaHandHoldingHand } from "react-icons/fa6";

const About = () => {
    const [counter, setcounter] = useState(false)
    return (
        <>
            <Softbanner pageName="About"/>

            <section className='aboutpage'>
                <Container>
                    <Row style={{"alignItems": "center"}}>
                        <Col sm={12} md={6}>
                            <div className='aboutpage_img_wrapper'>
                                <div className='about_big'>
                                    <img src={aboutbig} loading='lazy' alt='' className='img-fluid'/>
                                </div>
                                <div className='aboutbigimg-1'>
                                    <img src={aboutbig1} loading='lazy' alt='' className='img-fluid'/>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='about_big_wrapper'>
                                <Sectiontitle tagtext="About Us" sectiontext="We’re aspire to Enhance Your Stylish experiences"/>

                                <Tabs>
                                    <TabList>
                                        <Tab>Our Mission</Tab>
                                        <Tab>Our Vision</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed porta lacus, id congue velit. Integer facilisis orci turpis, lobortis rutrum massa cursus quis. Aenean vitae luctus nibh, at ornare enim. Sed elit massa, suscipit eget pellentesque quis, iaculis et lectus. Fusce viverra luctus est quis ullamcorper. Nulla dictum vestibulum nibh, sit amet sodales purus convallis quis. Integer pellentesque arcu sit amet pellentesque ultricies.</p>
                                    </TabPanel>
                                    <TabPanel>
                                    <p>Lorem Nunc quam magna, pharetra ac ornare at, vestibulum at dui. Nulla eget scelerisque lorem, eu imperdiet ante. Nullam ornare, nulla sit amet malesuada porttitor, odio urna gravida magna, nec fermentum arcu odio eget massa. Vestibulum mauris est, venenatis sed orci sit amet, feugiat facilisis ante. Nullam placerat diam sed mi imperdiet gravida. In sit amet turpis semper, cursus velit in, egestas nibh. Etiam tellus nunc, lobortis non porta blandit, volutpat ut nunc. Ut vel augue id massa pharetra vulputate. Nulla commodo nibh tincidunt ipsum vulputate, eget lobortis ligula ultrices. Vivamus sit amet tellus eget tellus tristique fringilla et non elit.</p>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

                    {/* ====numbers sec start==== */}
        <section className='numbers_sec'>
            <Container>
                <ScrollTrigger onEnter={()=>setcounter(true)} onExit={()=>{setcounter(false)}}>
                    <Row>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaTrophy /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={32} duration={2} delay={0} />}+</div>
                                    <p>Winning award</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaHeart /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={7} duration={2} delay={0} />}K</div>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaHandHoldingHand /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={24} duration={2} delay={0} />}X7</div>
                                    <p>Support</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaThumbsUp /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={850} duration={2} delay={0} />}+</div>
                                    <p>Project Done</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ScrollTrigger>
            </Container>
        </section>
        {/* ====numbers sec close==== */}

                {/* ===FAQ section start==== */}
                <section className='faq_sec'>
            <Container>
                <Row>
                    <Col sm={12} lg={6}>
                    <div className="faq-image">
                            <div className="image-one">
                                <img src={faq1} alt="faq-image-one"/>
                            </div>
                            <div className="image-two">
                                <img src={faq2} alt="faq-image-two" className="img-fluid w-100"/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <div className="accrodian_wrapper">
                        <Sectiontitle tagtext="SOME FAQ" sectiontext="Construction At Your Service A Passion Precision Pride"/>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What harsh truths do you prefer to ignore?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                        cupidatat occaecat ut occaecat consequat est minim minim
                                        esse tempor laborum consequat esse adipisicing eu
                                        reprehenderit enim.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is free will real or just an illusion?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                        ea in ut nostrud velit in irure cillum tempor laboris
                                        sed adipisicing eu esse duis nulla non.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is free will real or just an illusion?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                        ea in ut nostrud velit in irure cillum tempor laboris
                                        sed adipisicing eu esse duis nulla non.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* ===FAQ section start==== */}
        </>
    );
}

export default About;
