import React from 'react';
import expert1 from '../../../images/team/e-member1.jpg';
import '../expertcard/expertcard.css'
import { Link } from 'react-router-dom';
import Expertdata from './Expertdata';
import { Col } from 'react-bootstrap';

const ExpertCard = (props) => {
    return (
        <>
        <Col sm={4} md={4} lg={3}>
            <div className="expert_item">
                <div className="expert_img">
                    <Link to="#" href='' className="member_img"><img src={props.expImg} alt="" /></Link>
                    <div className="member_info">
                        <h5><Link to="#">{props.expName}</Link></h5>
                        <p>{props.expDesign}</p>
                    </div>
                </div>
            </div>
        </Col>
        </>
    );
}

export default ExpertCard;
