import React, { useState, useRef ,useEffect} from 'react';
import Softbanner from '../../components/softbanner/Softbanner';
import '../contact/contact.css'
import { Col, Container, Row } from 'react-bootstrap';
import ContactInfo from './ContactInfo';
import contactData from './ContactData' ;
import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import PrimaryBtn from '../../components/primaryBtn/PrimaryBtn';
import axios from 'axios';
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { API_URL } from '../../App';


const Contact = () => {

    const [Contactdata, setContactdata] = useState([]);
    const [ContactdataEmail, setContactdataEmail] = useState([]);

    const [ContactdataPhone, setContactdataPhone] = useState([]);
    // const api_uri=process.env.REACT_APP_API_KEY;
     console.log(API_URL);
    

    useEffect(() => {
      
     
      fetchContactData();
      //console.log(ExpertTemdata);
  
     //Ends
    }, []);

    const fetchContactData = async () => {
        try {
          const response = await axios.get(API_URL+'getContactDetail');
          setContactdata(response.data);
           // Logging response.data
           var dt=response.data;
           var cid=dt[0].id;
           //console.log(cid);

           const response2 = await axios.get(API_URL+'getContactDetailEmail/'+cid);
           setContactdataEmail(response2.data);

           const response3 = await axios.get(API_URL+'getContactDetailPhone/'+cid);
           setContactdataPhone(response3.data);

          
          
          
         
        } catch (error) {
          console.error('Error fetching data:', error);
          
        }
      };

      

    const firstElement = Contactdata.length > 0 ? Contactdata[0] : null;
    //console.log(firstElement);

    const firstEmail = ContactdataEmail.length > 0 ? ContactdataEmail[0] : null;
    const SecondEmail = ContactdataEmail.length > 0 ? ContactdataEmail[1] : null;


    const firstPhone = ContactdataPhone.length > 0 ? ContactdataPhone[0] : null;
    const SecondPhone = ContactdataPhone.length > 0 ? ContactdataPhone[1] : null;
    
    //console.log(api_uri);
    return (
        <>
       <Softbanner pageName="Contact Us"/>

       <section className='contactinfo'>
        <Container>
            <Row>
                {
                    // contactData.map((contactval)=>{
                        // return(
                            <ContactInfo 
                            conIcon=<FaPhoneAlt />
                             conTitle="CALL US 24/7"
                             
                              ConPara1={firstPhone && (firstPhone.contact_phone)}
                              ConPara2={SecondPhone && (SecondPhone.contact_phone)}
                              />

                              

                        //)
                   // })
                }

{
                    // contactData.map((contactval)=>{
                        // return(
                            <ContactInfo 
                            conIcon= <FaMapMarkerAlt />
                             conTitle="OUR LOCATION"
                              ConPara1={firstElement && (firstElement.contact_loaction)}
                           
                              />

                              

                        //)
                   // })
                }

                      {
                    // contactData.map((contactval)=>{
                        // return(
                            <ContactInfo 
                            conIcon=<FaPhoneAlt />
                             conTitle="MAIL US 24/7"
                              ConPara1={firstEmail && (firstEmail.contact_email)}
                              ConPara2={SecondEmail && (SecondEmail.contact_email)}
                              />

                              

                        //)
                   // })
                }

                
            </Row>
           
        </Container>
       </section>

       <section className='contact_form_wrapper'>
        <Container>
            <Row>
                <Col sm={4}>
                {firstElement && (
                <Sectiontitle tagtext="Contact Us" 
                
                sectiontext={firstElement.contact_title}
                
                /> 
                )}
                {firstElement && (
                      <p>{firstElement.contact_description}</p> 
                      )}
                   

                    <ul className='socialbar'>
                        <li><Link to="" className='btn primarybtn'><span><FaFacebookF/></span></Link></li>
                        <li><Link to="" className='btn primarybtn'><span><FaXTwitter /></span></Link></li>
                        <li><Link to="" className='btn primarybtn'><span><FaLinkedinIn/></span></Link></li>
                        <li><Link to="" className='btn primarybtn'><span><FaYoutube/></span></Link></li>
                    </ul>
                </Col>

                {/* <Col sm={8}>
                    <div className="form_outer">
                        <Form>
                            <b className='formtitle'>Let's Talk</b>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name='' placeholder="Full Name" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" name='' placeholder="Email ID" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control type="text" name='' placeholder="10 Digit Mobile No" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>Your Subject</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-control'>
                                        <option>Select Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={4} placeholder='Write Message' />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <PrimaryBtn btnText="submit" refLink="#"/>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col> */}
            </Row>
        </Container>
       </section>
        </>
    );
}

export default Contact;
