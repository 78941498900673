import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";


// Save the original console functions
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;

// Override console functions
console.error = function(message) {
  // Check if the error message comes from react-jsx-dev-runtime.development.js
  if (!message.includes('react-jsx-dev-runtime.development.js')) {
    // If not, call the original console.error
   // originalConsoleError.apply(console, arguments);
  }
};

console.warn = function(message) {
  // Check if the warning message comes from react-jsx-dev-runtime.development.js
  if (!message.includes('react-jsx-dev-runtime.development.js')) {
    // If not, call the original console.warn
    originalConsoleWarn.apply(console, arguments);
  }
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
