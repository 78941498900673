import React from 'react';
import { Col } from 'react-bootstrap';


const ContactInfo = (props) => {
    return (
        <Col sm={4}>
            <div className="contact_item">
                <div className="contact_icon">{props.conIcon}</div>
                <div className="contact_dtail">
                    <b>{props.conTitle}</b>
                    <p>{props.ConPara1}</p>
                    <p>{props.ConPara2}</p>
                </div>
            </div>
        </Col>
    );
}

export default ContactInfo;
