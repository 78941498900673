import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './includes/Headersec/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './css/style.css';
import './css/responsive.css';

import Team from './pages/team/Team';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Topbar from './includes/Topbar/Topbar';
import Footer from './includes/Footer/Footer';
import Products from './pages/Products/Products';
import Productdetails from './pages/ProductDetails/Productdetails';
import Services from './pages/Services/Serveces';
import ServiceDetails from './pages/ServiceDetails/Servicedetails';
import ProjectDetails from './pages/ProjectDetails/Projectdetails';
import About from './pages/About/About';
import Cms from './pages/Cms/Cms';
import Clients from './pages/Client/Clients';
import Demo from './pages/demo/Demo';
import React, { useEffect,useState } from 'react';
import axios from 'axios';
export const API_URL = 'https://cotex.thebizsolutions.com/cortex/api/';

function App() {

//   if (process.env.NODE_ENV === 'development') {
//     alert("xyz");
//     console.warn = () => {};
// }

  const [Cmsdata, setCmsdata] = useState([]);
  
 
  useEffect(() => {

    const fetchCmsData = async () => {
      try {
        const response = await axios.get(API_URL+'getCms');
        console.log('Response Data:', response.data); // Logging response.data
        setCmsdata(response.data);
       
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };
      
      
      fetchCmsData();
     

     //Ends
    }, []);

    console.log(Cmsdata);

   
  return (
    <>
    <Topbar/>
      <Header/>
      <Routes>
          <Route>
            <Route index element={<Home/>} />
            {/* <Route path="/" element={<Home/>} /> */}
            <Route path="home" element={<Home/>} />
            <Route path="about" element={<About/>} />
            <Route path="team" element={<Team/>} />
            <Route path="contact" element={<Contact/>} />
            <Route path="products" element={<Products/>} />
            <Route path="product-details/:slug" element={<Productdetails/>} />
            <Route path="clients" element={<Clients/>} />

            <Route path="services" element={<Services/>} />

            <Route path="service-details/:slug" element={<ServiceDetails/>} />

            <Route path="project-details/:slug" element={<ProjectDetails/>} />
            
            <Route path="free-demo" element={<Demo/>} />
            
            {Cmsdata.map((item, index) => (
            <Route path={item.cms_slug} element={<Cms/>} />
            ))}
           
            {/* <Route path="context" element={<ComB/>} /> */}
            {/* <Route path="*" element={<NoPage />} /> */}
          </Route>
        </Routes>
        <Footer/>
    </>
  );
}

export default App;
