import React from 'react';
import Herobanner from './Herobanner';
import "../home/home.css";
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useState,useEffect } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import icon1 from '../../images/svgicons/icon1.svg';
import icon2 from '../../images/svgicons/icon2.svg';
import icon3 from '../../images/svgicons/icon3.svg';
import icon4 from '../../images/svgicons/icon4.svg';
import icon5 from '../../images/svgicons/icon5.svg';
import icon6 from '../../images/svgicons/icon6.svg';

import { FaRegCircleCheck } from "react-icons/fa6";
import { FaTrophy } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa6";
import { FaHandHoldingHand } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

import aboutImage from '../../images/about-1.png';
import faq1 from '../../images/faq-image-one.png';
import faq2 from '../../images/faq-image-two.png';

import LatestSlide from './LatestSlide';
import ExpertCard from '../team/expertcard/ExpertCard';
import Expertdata from '../team/expertcard/Expertdata';
import TestimonialSlide from './TestimonialSlide';
import Verticleslider from './Verticleslider';
import Recentworkslide from './Recentworkslide';

import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Brandslider from './Brandslider';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../App';




const Home = () => {
    const slides = [
        {
          imageUrl: icon1,
          altText: 'Image 1',
        },
        {
          imageUrl: icon2,
          altText: 'Image 2',
        },
        {
          imageUrl: icon3,
          altText: 'Image 3',
        },
      ];
      const [ExpertTemdata, setExpertTemdata] = useState(null);
    const [counter, setcounter] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        
        const fetchTeamExpertData = async () => {
          try {
            const response = await axios.get(API_URL+'getTeamExpert');
            console.log('Response Data:', response.data); // Logging response.data
            setExpertTemdata(response.data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        fetchTeamExpertData();
        //console.log(ExpertTemdata);






    
       //Ends
      }, []);
    return (
        <>
        {/* =====banner start====== */}
            <section className='hero_banenr'>
                <Herobanner/>
            </section>
        {/* =====banner close====== */}


        {/* ====quick wrapper start==== */}
            <section className='quick_sec'>
                <Container>
                    
                    <Row>
                        <Col sm={12}>
                            <div className="quick_wrapper">
                                <div className="quick_item">
                                    <div className="quickimg">
                                    <img src={icon1} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>Health Monitoring</b>
                                    </div>
                                </div>
                                <div className="quick_item">
                                    <div className="quickimg">
                                    <img src={icon2} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>NDT- Forensic Investigation</b>
                                    </div>
                                </div>
                                <div className="quick_item">
                                    <div className="quickimg">
                                    <img src={icon3} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>Stability Certification</b>
                                    </div>
                                </div>
                                <div className="quick_item">
                                    <div className="quickimg">
                                    <img src={icon4} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>Residual Life Assessment</b>
                                    </div>
                                </div>
                                <div className="quick_item">
                                    <div className="quickimg">
                                        <img src={icon5} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>Load Carrying Capacity</b>
                                    </div>
                                </div>
                                <div className="quick_item">
                                    <div className="quickimg">
                                        <img src={icon6} alt="" />
                                    </div>
                                    <div className="quick_content">
                                        <b>Safety Audit of Plants</b>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        {/* ====quick wrapper close==== */}
        
        {/* ====About sec start==== */}
        <section className='about_sec'>
            <Container>
                <Row>
                <Col sm={12} md={12} lg={6} xl={6}>
                    <div className="about_img">
                        <img src={aboutImage} alt="" className='img-fluid' />
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6}>
                    <div className="about_wrapper">
                        <Sectiontitle tagtext="Who we are" sectiontext="Recognized as one of the leading"/>
                        <p>Cortex Construction Solutions Pvt. Ltd. is a diversified consulting firm providing civil structural stability and safety solutions to industrial, heritage, commercial and infrastructure clients for last ten years. Cortex has developed its own age analysis, structural health & stability assessment, real time structural health monitoring, residual life assessment, conservation and restoration plan, and vulnerability analysis tools and techniques for civil structures.</p>

                        <ul className="points_listing">
                            <li>
                                <span><FaRegCircleCheck /></span>
                                <b>We Always Building Quality Industrial</b>
                            </li>
                            <li>
                                <span><FaRegCircleCheck /></span>
                                <b>We Always Building Quality Industrial</b>
                            </li>
                            <li>
                                <span><FaRegCircleCheck /></span>
                                <b>We Always Building Quality Industrial</b>
                            </li>
                        </ul>
                    </div>
                </Col>
                </Row>
            </Container>
        </section>
        {/* ====About sec close==== */}

        {/* ====numbers sec start==== */}
        <section className='numbers_sec'>
            <Container>
                <ScrollTrigger onEnter={()=>setcounter(true)} onExit={()=>{setcounter(false)}}>
                    <Row>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaTrophy /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={32} duration={2} delay={0} />}+</div>
                                    <p>Winning award</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaHeart /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={7} duration={2} delay={0} />}K</div>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaHandHoldingHand /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={24} duration={2} delay={0} />}X7</div>
                                    <p>Support</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={3}>
                            <div className="countup_item">
                                <div className="countup_icon"><FaThumbsUp /></div>
                                <div className="countup_detl">
                                    <div className="count_no">{counter && <CountUp start={0} end={850} duration={2} delay={0} />}+</div>
                                    <p>Project Done</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ScrollTrigger>
            </Container>
        </section>
        {/* ====numbers sec close==== */}
        
        {/* ====latest project start==== */}
        <section className='latest_sec'>
            <Container>
                <Row>
                    <Col sm={8}>
                        <Sectiontitle tagtext="Latest Project" sectiontext="Explore Recent Projects"/>
                    </Col>
                    <Col sm={4}>
                        <a href="" className="btn btnprimary"><span>View All</span></a>
                    </Col>
                </Row>
            </Container>
            <LatestSlide/>
        </section>
        {/* ====latest project close==== */}

        {/* ===our team start==== */}
         <section className='home_team'>
         <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="text-center">
                                <Sectiontitle tagtext="Meet the experts" sectiontext="Our Leadership Team"/>
                            </div>
                        </Col>
                    </Row>
                    {ExpertTemdata && (
                    <Row className='justify-content-center'>
                        {
                            ExpertTemdata.map((expertval)=>{
                                return(
                                    <ExpertCard
                                     expImg = {expertval.team_image_path}
                                      expName ={expertval.team_name}
                                       expDesign= {expertval.team_role}/>
                                )
                            })
                        }
                    </Row>
                     )}
                    <Row>
                        <Col sm={12} className='text-center'><Link to={'/team'} className="btn btnprimary"><span>View All</span></Link></Col>
                    </Row>
                </Container>
         </section>
        {/* ===our team close==== */}

        {/* ===testimonial sec start==== */}
         <section className='testimonial_sec'>
            <Container>
                <Row>
                    <Col sm={12} lg={4}>
                        <div className="testimonial_wrapper">
                            <div className="quote_icon">
                            <FaQuoteLeft />
                            </div>
                            <Sectiontitle tagtext="What People Say" sectiontext="Trusted From Over 2,500 Clients"/>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore consectetur optio animi quasi quae. Beatae eius nam porro.</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={8}>
                        <TestimonialSlide/>
                    </Col>
                </Row>
            </Container>
         </section>
        {/* ===testimonial sec close==== */}

        {/* ===news letter sec start== */}
        <section className='newsletter_sec'>
            <Container>
                <Row style={{alignItems: 'center'}}>
                    <Col sm={6}>
                        <div className="newsletter_title">
                        <Sectiontitle tagtext="Newsletter" sectiontext="Subscribe to our Newsletter"/>
                        <p>Subscribe to our email to get exclusive discounts and offers.</p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <form className='newsletter_form'>
                            <label><IoMdMail /> Email Id</label>
                            <div className="form_wrapper">
                            <input type="text" className='form-control' placeholder='Enter Your Email Address' />
                            <button class="btn btnprimary"><span>Subscribe</span></button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* ===news letter sec close== */}

        {/* ===our recent work==== */}
        <section className='recent_work'>
            <Container>
                <Row>
                    <Col sm={12} md={8}>
                        <Sectiontitle tagtext="Recent Work" sectiontext="Recently Monitored Projects"/>
                        <Recentworkslide/>
                    </Col>
                    <Col sm={12} md={4}>
                        <div className='Prestigious_wrapper'>
                            <div className="subtitle">Our Prestigious Projects</div>
                            <Verticleslider slides={slides} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* ===our recent work==== */}

        {/* ===brands slider start==== */}
        <section className='our_brands'>
            <Container>
                <div className="brandwrapper">
                    <Row>
                        <Col sm={12}>
                            <Brandslider/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
        {/* ===brands slider close==== */}



        {/* ===FAQ section start==== */}
        <section className='faq_sec'>
            <Container>
                <Row>
                    <Col sm={12} lg={6}>
                    <div className="faq-image">
                            <div className="image-one">
                                <img src={faq1} alt="faq-image-one"/>
                            </div>
                            <div className="image-two">
                                <img src={faq2} alt="faq-image-two" class="img-fluid w-100"/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <div className="accrodian_wrapper">
                        <Sectiontitle tagtext="SOME FAQ" sectiontext="Construction At Your Service A Passion Precision Pride"/>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What harsh truths do you prefer to ignore?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                        cupidatat occaecat ut occaecat consequat est minim minim
                                        esse tempor laborum consequat esse adipisicing eu
                                        reprehenderit enim.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is free will real or just an illusion?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                        ea in ut nostrud velit in irure cillum tempor laboris
                                        sed adipisicing eu esse duis nulla non.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is free will real or just an illusion?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                        ea in ut nostrud velit in irure cillum tempor laboris
                                        sed adipisicing eu esse duis nulla non.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* ===FAQ section start==== */}
        </>


    );
}

export default Home;
