import React,{ useEffect, useState,useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import Softbanner from '../../components/softbanner/Softbanner';
import '../team/team.css';
import Expertdata from '../team/expertcard/Expertdata';
import ExpertCard from './expertcard/ExpertCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Teamcard from './teamcards/Teamcard';
import teamdata from './teamcards/teamdata';
import axios from 'axios';
import { API_URL } from '../../App';


const Team = () => {
   
    const [ExpertTemdata, setExpertTemdata] = useState(null);
    const [FirstValue, setFirstValue] = useState(null);
    const [AllTemdata, setAllTemdata] = useState(null);
    const [ServiceType, setServiceType] = useState();
    const dependencyRef = useRef(ServiceType);
    const [loading, setLoading] = useState(true);
    const items = ['leadership', 'client-service', 'creative', 'development', 'marketing-sales', 'stategy'];

    const [ItemsNew, setItemsNew] = useState([]);



    
  

     

    

   

    useEffect(() => {

     
       
        const fetchTeamExpertData = async () => {
          try {
            const response = await axios.get(API_URL+'getTeamExpert');
            console.log('Response Data:', response.data); // Logging response.data
            setExpertTemdata(response.data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        fetchTeamExpertData();
        //console.log(ExpertTemdata);


        const fetchTeamAllData = async () => {
            setServiceType('leadership');
           

            const getFirstVal = await axios.get(API_URL+'getActiveTeamType');

            setItemsNew(getFirstVal.data.ItemTypeArr);

            var dt=getFirstVal.data.first_team_type;

            try {
              const response = await axios.get(API_URL+'getTeamAllByService/'+dt);
              console.log('Response Data:', response.data); // Logging response.data
              setAllTemdata(response.data);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching data:', error);
              setLoading(false);
            }
          };
          fetchTeamAllData();


          //sendData(ServiceType);

          //setServiceType('leadership');

      


         
         
    
       //Ends
      }, []);
      
      console.log(ServiceType);
      const getInitial= async () => {
        // Example: Compute initial count based on some logic
       
       
         
      
        
        return 'leadership'; // Random number between 0 and 9
      }

     

     
      

     

      const sendData = async (ServiceType) => {

        const responseByService = await axios.get(API_URL+'getTeamAllByService/'+ServiceType);
            
              setAllTemdata(responseByService.data);
      }

      const handleClick = async (event) => {
        const dataId = event.target.dataset.id;
        
        setServiceType(dataId);

        sendData(dataId);
        
              
      };
      console.log(ServiceType);

    return (
        <>
        <div className="team_page">
            <Softbanner pageName="Our Team"/>
            <div className="leadership_sec">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="text-center">
                                <Sectiontitle tagtext="Meet the experts" sectiontext="Our Leadership Team"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                {ExpertTemdata && (
                    <Row className='justify-content-center'>
                        {
                            ExpertTemdata.map((expertval)=>{
                                return(
                                    <ExpertCard
                                     expImg = {expertval.team_image_path}
                                      expName ={expertval.team_name}
                                       expDesign= {expertval.team_role}/>
                                )
                            })
                        }
                    </Row>
                    )}
                </Container>
            </div>

            <section className='whole_team'>
            <Tabs>
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={12} md={12} lg={4} xl={5}>
                                <Sectiontitle tagtext="PROFESSIONAL EXPERTs" sectiontext="Team Members"/>
                        </Col>
                        <Col sm={12} md={12} lg={8} xl={7}>
                            <TabList>
                            { ItemsNew && ItemsNew.map((item, index) => (
                                <Tab onClick={handleClick} data-id={item.team_type_slug}>{item.team_type}</Tab>
                                ))}
                             
                            </TabList>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className="team_wrapper">
                            {ItemsNew.map((item, index) => (
                            <TabPanel>
                            {AllTemdata && (
                                <Row>
                                    {
                                        AllTemdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.team_image_path} teamName={teaminfo.team_name} teamDsg={teaminfo.team_role}/>
                                            )
                                        })
                                    }
                                </Row>
                                 )}
                            </TabPanel>
                             ))}

                            {/* <TabPanel>
                                <Row>
                                    {
                                        teamdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.expertImg} teamName={teaminfo.expertName} teamDsg={teaminfo.expertDesg}/>
                                            )
                                        })
                                    }
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    {
                                        teamdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.expertImg} teamName={teaminfo.expertName} teamDsg={teaminfo.expertDesg}/>
                                            )
                                        })
                                    }
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    {
                                        teamdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.expertImg} teamName={teaminfo.expertName} teamDsg={teaminfo.expertDesg}/>
                                            )
                                        })
                                    }
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    {
                                        teamdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.expertImg} teamName={teaminfo.expertName} teamDsg={teaminfo.expertDesg}/>
                                            )
                                        })
                                    }
                                </Row>
                            </TabPanel>
                            <TabPanel>
                                <Row>
                                    {
                                        teamdata.map((teaminfo)=>{
                                            return(
                                                <Teamcard teamImg={teaminfo.expertImg} teamName={teaminfo.expertName} teamDsg={teaminfo.expertDesg}/>
                                            )
                                        })
                                    }
                                </Row>
                            </TabPanel> */}
                            </div>                        
                        </Col>
                    </Row>
                </Container>
                </Tabs>
            </section>
        </div>
        </>
    );
}

export default Team;
