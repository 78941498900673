import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import slide3 from '../../images/banner/slider-bg3.jpg'
import { Col, Container, Row } from 'react-bootstrap';

const Herobanner = () => {
    const options = {
        items: 1,
        nav: true,
        rewind: true,
        loop: true,
        autoplay: false,
        slideBy: 1,
        nav: false,
        dots: true,
        dotsEach: true,
        dotData: true
      };

    return (
        <>
        <OwlCarousel className='owl-theme' {...options}>
            <div className='banneritem'>
                <img src={slide3} alt="" />
                <div className="hero_wrapper">
                    <Container>
                        <Row>
                            <Col sm={8} lg={6}>
                            <div className="hero-despription">
                                    <b>Transforming your infrastructure into living assets</b>
                                    <h1>BRIDGE LOAD RATING PRE-POST DISASTER <span>SURVEY</span></h1>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum est, pariatur tenetur doloremque voluptate delectus.</p>

                                    <a href="" className="btn btnprimary"><span>View Details</span></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className='banneritem'>
                <img src={slide3} alt="" />
                <div className="hero_wrapper">
                    <Container>
                        <Row>
                            <Col sm={8} lg={6}>
                            <div className="hero-despription">
                                    <b>Transforming your infrastructure into living assets</b>
                                    <h1>BRIDGE LOAD RATING PRE-POST DISASTER <span>SURVEY</span></h1>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum est, pariatur tenetur doloremque voluptate delectus.</p>

                                    <a href="" className="btn btnprimary"><span>View Details</span></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className='banneritem'>
                <img src={slide3} alt="" />
                <div className="hero_wrapper">
                    <Container>
                        <Row>
                            <Col sm={8} lg={6}>
                            <div className="hero-despription">
                                    <b>Transforming your infrastructure into living assets</b>
                                    <h1>BRIDGE LOAD RATING PRE-POST DISASTER <span>SURVEY</span></h1>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum est, pariatur tenetur doloremque voluptate delectus.</p>

                                    <a href="" className="btn btnprimary"><span>View Details</span></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </OwlCarousel>
        </>
    )
}

export default Herobanner;
