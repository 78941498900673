import React from 'react';
import '../sectiontitle/sectiontitle.css'

const Sectiontitle = (props) => {
    return (
        <>
        <div className="sectiontitle">
                <b className="sectiontag">{props.tagtext}</b>
                <h2 className="sectiontitle_text">{props.sectiontext}</h2>
        </div>
        </>
    );
}

export default Sectiontitle;
