import React from 'react';
import { useState,useEffect } from 'react';
import Softbanner from '../../components/softbanner/Softbanner';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Col, Container, Row } from 'react-bootstrap';
import Sectiontitle from '../../components/sectiontitle/Sectiontitle';
import '../Client/clients.css';

import prj1 from '../../images/projects/projects-img1.png';
import prj2 from '../../images/projects/projects-img2.png';
import prj3 from '../../images/projects/projects-img3.png';
import prj4 from '../../images/projects/projects-img4.png';
import icon1 from '../../images/svgicons/icon1.svg';
import icon2 from '../../images/svgicons/icon2.svg';
import icon3 from '../../images/svgicons/icon3.svg';
import { Link } from 'react-router-dom';

import { FaArrowRight } from "react-icons/fa6";
import Recentworkslide from '../home/Recentworkslide';
import Verticleslider from '../home/Verticleslider';
import Brandslider from '../home/Brandslider';
import axios from 'axios';
import { API_URL } from '../../App';

const Clients = () => {
    const slides = [
        {
          imageUrl: icon1,
          altText: 'Image 1',
        },
        {
          imageUrl: icon2,
          altText: 'Image 2',
        },
        {
          imageUrl: icon3,
          altText: 'Image 3',
        },
      ];

      const [ProjectMonitorRecentdata, setProjectMonitorRecentdata] = useState(null);
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL+'getProjectMonitorRecent');
        // console.log('Response Data:', response.data); // Logging response.data
        setProjectMonitorRecentdata(response.data);
        
       
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };
    fetchData();
    //console.log(ExpertTemdata);

   //Ends
  }, []);
     
    return (
        <>
            <Softbanner pageName="Clients"/>

            {/* ===brands slider start==== */}
                <section className='our_brands client_brands'>
                    <Container>
                        <div className="brandwrapper">
                            <Row>
                                <Col sm={12} className='text-center'>
                                <Sectiontitle tagtext="Our Clients" sectiontext="some of Our Clients"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Brandslider/>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            {/* ===brands slider close==== */}

            {/* <section className='our-project'>
            <Tabs>
                <Container>
                    <div className='projects_header'>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Sectiontitle tagtext="LATEST PROJECTS" sectiontext="Let's Checkout Our Recently Completed Work"/>
                            </Col>
                            <Col sm={12} lg={6}>
                                <TabList>
                                    <Tab>All</Tab>
                                    <Tab>Digital</Tab>
                                    <Tab>Engineering</Tab>
                                    <Tab>Monitoring</Tab>
                                    <Tab>Mapping</Tab>
                                </TabList>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm={12}>
                            <TabPanel>
                                <Row>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj2} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj3} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj4} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj2} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj2} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj3} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj4} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj2} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj3} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPanel>

                            <TabPanel>
                                <Row>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj3} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className='project_item'>
                                            <Link to={""} className='project_img'><img src={prj1} alt='' className='img-fluid'/></Link>
                                            <div className='project_description'>
                                                <Link to={""} className='p_name_title'>Project Name</Link>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <Link to={""} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPanel>
                        </Col>
                    </Row>
                </Container>
            </Tabs>
            </section> */}

        {/* ===our recent work==== */}
        <section className='client_recent'>
            <Container>
                <Row>
                    <Col sm={12} md={8}>
                        <Sectiontitle tagtext="Recent Work" sectiontext="Recently Monitored Projects"/>
                        <Row>
                        { ProjectMonitorRecentdata && ProjectMonitorRecentdata.map((item, index) => (
                                <Col sm={6} lg={6}>
                                    <div className='project_item'>
                                        <Link to={'/project-details/'+item.project_slug} className='project_img'><img src={item.project_image_path} alt='' className='img-fluid'/></Link>
                                        <div className='project_description'>
                                            <Link to={'/project-details/'+item.project_slug} className='p_name_title'>{item.project_name}</Link>
                                           

                                            <div dangerouslySetInnerHTML={{ __html: item.project_description }} />

                                            <Link to={'/project-details/'+item.project_slug} className='btn projetviewbtn'>View More <FaArrowRight /></Link>
                                        </div>
                                    </div>
                                </Col>
                                  ))} 
                               
                        </Row>
                    </Col>
                    <Col sm={12} md={4}>
                        <div className='stickyouter'>
                        <div className='clientPrestigious_wrapper'>
                            <div className="subtitle">Our Prestigious Projects</div>
                            <Verticleslider slides={slides} />
                        </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* ===our recent work==== */}



        </>
    );
}

export default Clients;
