import React,{ useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import recent1 from '../../images/recent1.jpg';
import recent2 from '../../images/recent2.jpg';

import { FaArrowRight } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import axios from 'axios';
import { API_URL } from '../../App';

const Recentworkslide = () => {
    const options = {
        nav: false,
        rewind: true,
        margin: 0,
        loop: true,
        autoplay: true,
        slideBy: 1,
        dots: false,
        dotsEach: true,
        dotData: true,
        responsive:{
            0:{
                items:1
            },
            576:{
                items:2
            },
            768:{
                items:1
            },
            992:{
                items:2
            }
        }
      };

      const [ProjectMonitordata, setProjectMonitordata] = useState(null);
      useEffect(() => {
        
        const fetchProjectMonitorData = async () => {
          try {
            const response = await axios.get(API_URL+'getProjectMonitor');
            // console.log('Response Data:', response.data); // Logging response.data
            setProjectMonitordata(response.data);
           
          } catch (error) {
            console.error('Error fetching data:', error);
            
          }
        };
        fetchProjectMonitorData();
        //console.log(ExpertTemdata);

       //Ends
      }, []);

    return (
        <>
                <OwlCarousel className='owl-theme' {...options}>
                { ProjectMonitordata && ProjectMonitordata.map((item, index) => (        
            <div class='item'>
                <div className="recent_item">
                    <div className="service-slider-area p-relative">
                            <div className="recent_img">
                            <a href={'/project-details/'+item.project_slug}>
                                <img src={item.project_image_path} alt="" className='img-fluid' />
                                </a>
                                </div>
                            <div class="content-area">
                            <div class="content text-center">
                                <h5 class="mb-10">
                                <a href={'/project-details/'+item.project_slug}>
                                    {item.project_name}
                                    </a>
                                    </h5>
                                {/* <p><FaLocationDot /> Kolkata</p> */}
                            </div>
                        </div>
                        <a class="btn-icon" href="#"><FaArrowRight /></a>
                    </div>
                </div>
            </div>
             ))} 
            {/* <div class='item'>
                <div className="recent_item">
                    <div className="service-slider-area p-relative">
                            <div className="recent_img"><img src={recent2} alt="" className='img-fluid' /></div>
                            <div class="content-area">
                            <div class="content text-center">
                                <h5 class="mb-10">Structural Bridge</h5>
                                <p><FaLocationDot /> London</p>
                            </div>
                        </div>
                        <a class="btn-icon" href="#"><FaArrowRight /></a>
                    </div>
                </div>
            </div>
            <div class='item'>
                <div className="recent_item">
                    <div className="service-slider-area p-relative">
                            <div className="recent_img"><img src={recent1} alt="" className='img-fluid' /></div>
                            <div class="content-area">
                            <div class="content text-center">
                                <h5 class="mb-10">Structural Bridge</h5>
                                <p><FaLocationDot /> Kolkata</p>
                            </div>
                        </div>
                        <a class="btn-icon" href="#"><FaArrowRight /></a>
                    </div>
                </div>
            </div> */}
        </OwlCarousel></>
    );
}

export default Recentworkslide;
