import React, {useEffect, useState } from 'react';
import '../Services/service.css'
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slider';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";

import product1 from '../../images/product/1-1.png'
import product2 from '../../images/product/1-2.png'
import product3 from '../../images/product/1-3.png'
import Softbanner from '../../components/softbanner/Softbanner';
import axios from 'axios';
import { API_URL } from '../../App';


import Modal from 'react-modal';


const MIN = 100;
const MAX = 12000;

const MAX_RETRIES = 3;
const INITIAL_DELAY = 1000; // 1 second

// const axios = require('axios');
// const retry = require('axios-retry');



const Services = () => {
    const [values, setValues]= useState([MIN, MAX]);

    const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [Servicedata, setServicedata] = useState([]);
  
  const [ServicedataDDL, setServicedataDDL] = useState([]);


  const [ServiceTypeData, setServiceTypeData] = useState([]);


  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);



  const [retryAttempts, setRetryAttempts] = useState(0);

  const [error, setError] = useState(null);

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

    console.log(process.env.NODE_ENV);
    


    const [sort, setSort] = useState("Default");
    const handleChange = async (event) => {
        setSort(event.target.value)
        const val = event.target.value;
        //alert(val);
        setLoading(true); // Start loading
        setModalIsOpen(true);
        var sType='no_value';


        
       
        if(selectedCheckboxes.join(',') !=='')
        {
            sType=selectedCheckboxes.join(',');

        }
       

        if(val=='all')
        {
            try {
                setTimeout(async () => {
                const response = await axios.get(API_URL+'getServiceAllDll/'+sType);
                setLoading(false); // Stop loading
                setModalIsOpen(false);
                console.log('Response Data:', response.data); // Logging response.data
                setServicedata(response.data);
            }, 2000);
              
                
              } catch (error) {
                console.error('Error fetching data:', error);
                
              }

        }
        else
        {
            try {
                setTimeout(async () => {
                const response = await axios.get(API_URL+'getSingleServiceBySlug/'+val+'/'+sType);
                setLoading(false); // Stop loading
                setModalIsOpen(false);
                console.log('Response Data:', response.data); // Logging response.data
                setServicedata(response.data);
            }, 2000);
                
              } catch (error) {
                console.error('Error fetching data:', error);
                
              }

        }
        
        

    }

   

   

   
 

    const handleCheckboxChange =  (event) => {
        const checkboxId = event.target.value;
       
        setSelectedCheckboxes(prevSelectedCheckboxes => {
          if (event.target.checked) {
            
            return [...prevSelectedCheckboxes, checkboxId];
          } else {
            console.log(checkboxId);
            return prevSelectedCheckboxes.filter(id => id !== checkboxId);
          }
        });

        
       
      
       
      };



      
     

      const wait = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      };
    

    useEffect(() => {
        
        const fetchServiceData = async () => {
           
          try {
            if(selectedCheckboxes.join(',')=='')
            {
            setLoading(true); // Start loading
            setModalIsOpen(true);
            console.log(sort);
            setTimeout(async () => {
            const response = await axios.get(API_URL+'getServiceAll');
            console.log(selectedCheckboxes.join(','));
            //console.log('Response Data:', response.data); // Logging response.data
            setLoading(false); // Stop loading
            setModalIsOpen(false);
            setServicedata(response.data);
            setServicedataDDL(response.data);
        }, 2000);
      }
            
          } catch (error) {
            console.error('Error fetching data:', error);
            
          }
        };
        fetchServiceData();


        const fetchServiceSortData = async () => {
           
            try {
              if(selectedCheckboxes.join(',')=='')
              {
              setLoading(true); // Start loading
              setModalIsOpen(true);
             
              setTimeout(async () => {
              const response = await axios.get(API_URL+'getServiceSortAll/'+sort);
              console.log(selectedCheckboxes.join(','));
              //console.log('Response Data:', response.data); // Logging response.data
              setLoading(false); // Stop loading
              setModalIsOpen(false);
              setServicedata(response.data.itemsSort);
              setServicedataDDL(response.data.itemsAll);
          }, 2000);
        }
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };
          


        
        const fetchServiceType = async () => {
            try {
                setTimeout(async () => {
              const response = await axios.get(API_URL+'getServiceTypeAll');
              //console.log('Response Data:', response.data); // Logging response.data
              setServiceTypeData(response.data);
            }, 2000);
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };
          fetchServiceType();

          const sendData = async (idsString) => {

        if(idsString !='')
        {
            setLoading(true); // Start loading
            setModalIsOpen(true);
         
            try {
                setTimeout(async () => {
                const response = await axios.get(API_URL+'getServiceFilterByType/'+idsString+'/'+sort);
               
                setLoading(false); // Stop loading
                setModalIsOpen(false);
                setServicedata(response.data);
            }, 2000);
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    await wait(1000); // Wait for 5 seconds before retrying
                    sendData(selectedCheckboxes.join(','));
                }
                
            }

      

        }

        else{
            fetchServiceSortData();

        }





        };


          sendData(selectedCheckboxes.join(','));
          
          //

         

       //Ends
      }, [selectedCheckboxes,retryAttempts]);

    
      //console.log(selectedCheckboxes);
     
     
      


    return (
        <>
        <Softbanner pageName="Services"/>
        <section className='produts_sec'>
            <Container>
                <Row>
                    <Col sm={4} lg={3}>
                        <div className="product_sidebar">
                            <Form>
                                <div className="filter_options">
                                    <div className="filter_title">Service Type</div>
                                    <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                shouldCloseOnOverlayClick={false} // Prevent close on overlay click
                style={customStyles}
                contentLabel="Loading Modal"
            >
                <h2>Loading...</h2>
            </Modal>
                                    <ul>
                                    { ServiceTypeData && ServiceTypeData.map((item, index) => (
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={item.service_type_uid} id={item.service_type_uid}    data-id={item.service_type_uid} onChange={ handleCheckboxChange} />
                                                <label class="form-check-label" for={item.service_type_uid}>
                                                {item.service_type_name}
                                                </label>
                                            </div>
                                        </li>
                                         ))}
                                        
                                       
                                    </ul>
                                </div>
                                {/* <div className="filter_options mt-50">
                                    <div className="filter_title">Price Range</div>

                                    <div className={"values"}>Price: ${values[0]}- ${values[1]}</div>
                                    <Slider className={"slider"} onChange={setValues} value={values} min={MIN} max={MAX}/>
                                    <small className='pricetag'>Current Range: ${values[1] - values[0]}</small>
                                </div> */}
                                {/* <div className="filter_options mt-50">
                                    <div className="filter_title">Solution Objectives</div>
                                    <ul>
                                    { ProductSolutiondata && ProductSolutiondata.map((item, index) => (
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={item.product_solution_object_uid} id="Displacement" onChange={(e) => handleSolutionCheckboxChange(e, selectedSolutionCheckboxes.join(','))} />
                                                <label class="form-check-label" for="Displacement">
                                                    {item.product_solution_object_name}
                                                </label>
                                            </div>
                                        </li>
                                         ))}
                                     
                                    </ul>
                                </div> */}
                            </Form>
                        </div>
                    </Col>
                    <Col sm={8} lg={9}>
                        <div className='productpage_header'>
                            <div className='filter_result'></div>
                            <ul className='sort_product'>
                                <li><p class="lan_tag">Filter By Name: </p></li>
                                <li><form>
                                    <select value={sort} onChange={handleChange} className='form-select'>
                                        <option value="all">All</option>
                                        { ServicedataDDL && ServicedataDDL.map((item, index) => (
                                        <option value={item.service_slug}>{item.service_name}</option>
                                        ))}
                                        
                                    </select>
                                    </form>
                                </li>
                            </ul>
                        </div>
                        <div className='Product_area'>
                            <Row>
                          

           { Servicedata && Servicedata.length > 0 ? ( Servicedata.map((item, index) => (   
                              <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        {/* <div className='producttag'>Most Popular</div> */}
                                        <Link to={'/service-details/'+item.service_slug}  className='product_img'>
                                            <img src={item.service_image_path} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>{item.service_name}</Link>
                                            {/* <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div> */}
                                            <div className='priceamount'
                                            >
                                                {/* <del>$30</del>  */}
                                            <span>{item.service_type_name}</span></div>
                                        </div>
                                    </div>
                                </Col> 
                                ))
                                ) : (
                                    <div>No service available</div>
                                )}
                             
                                {/* <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product3} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product2} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <div className='producttag'>Most Popular</div>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product3} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product1} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default Services;
