import React, { useState, useRef ,useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import vslide1 from '../../images/vslide1.jpg'
import vslide2 from '../../images/vslide2.jpg'
import vslide3 from '../../images/vslide3.jpg'
import vslide4 from '../../images/vslide4.jpg'
import axios from 'axios';
import { API_URL } from '../../App';
import { Link } from 'react-router-dom';

const Verticleslider = ({ slides }) => {


  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    pauseOnHover:false,
    draggable:true,
    swipeToSlide: true,
    autoplaySpeed: 2000
  };

  const [ProjectPrestigiousdata, setProjectPrestigiousdata] = useState(null);
  useEffect(() => {
    
    const fetchProjectPrestigiousData = async () => {
      try {
        const response = await axios.get(API_URL+'getProjectPrestigious');
        // console.log('Response Data:', response.data); // Logging response.data
        setProjectPrestigiousdata(response.data);
       
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };
    fetchProjectPrestigiousData();
    //console.log(ExpertTemdata);

   //Ends
  }, []);

  return (
    
    <div className="slider-container">
    <Slider ref={slider => (sliderRef = slider)} {...settings}>
    { ProjectPrestigiousdata && ProjectPrestigiousdata.map((item, index) => (  
      <div className="v-slideitem">
        <Link to={'/project-details/'+item.project_slug} className='project_img'>
        <img src={item.project_image_path} alt="" />
        </Link>
        <div className="v_overley">
            <h5><a href={'/project-details/'+item.project_slug}>{item.project_name}</a></h5>
        </div>
      </div>
        ))} 
      {/* <div className="v-slideitem">
        <img src={vslide2} alt="" />
        <div className="v_overley">
            <h5>Lorem ipsum dolor</h5>
        </div>
      </div>
      <div className="v-slideitem">
        <img src={vslide3} alt="" />
        <div className="v_overley">
            <h5>Lorem ipsum dolor</h5>
        </div>
      </div>
      <div className="v-slideitem">
        <img src={vslide4} alt="" />
        <div className="v_overley">
            <h5>Lorem ipsum dolor</h5>
        </div>
      </div> */}
    </Slider>
    <div className='playbar' style={{ textAlign: "center" }}>
      <button className="btn ppbtn" onClick={play}>
        Play
      </button>
      <button className="btn ppbtn" onClick={pause}>
        Pause
      </button>
    </div>
  </div>
  );
};

export default Verticleslider;
