import React, {useEffect, useState } from 'react';
import '../Products/product.css'
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slider';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";

import product1 from '../../images/product/1-1.png'
import product2 from '../../images/product/1-2.png'
import product3 from '../../images/product/1-3.png'
import Softbanner from '../../components/softbanner/Softbanner';
import axios from 'axios';
import { API_URL } from '../../App';
import Modal from 'react-modal';


const MIN = 100;
const MAX = 12000;

const MAX_RETRIES = 3;
const INITIAL_DELAY = 1000; // 1 second

// const axios = require('axios');
// const retry = require('axios-retry');



const Products = () => {
    const [values, setValues]= useState([MIN, MAX]);


    const [sort, setSort] = useState("Default");
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const [Productdata, setProductdata] = useState([]);

    const [ProductdataDLL, setProductdataDLL] = useState([]);
  
    

    const [ProductApplicationdata, setProductApplicationdata] = useState([]);

    const [ProductSolutiondata, setProductSolutiondata] = useState([]);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const [selectedSolutionCheckboxes, setSelectedSolutionCheckboxes] = useState([]);

    const [retryAttempts, setRetryAttempts] = useState(0);

    const [error, setError] = useState(null);

 

  
  
    const customStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
      },
  };

    const handleChange = async (event) => {
        setSort(event.target.value)
        const val = event.target.value;
        setLoading(true); // Start loading
        setModalIsOpen(true);

        var appType='no_value';

        var solType='no_value';

        if(selectedCheckboxes.join(',') !=='')
        {
            appType=selectedCheckboxes.join(',');

        }

        if(selectedSolutionCheckboxes.join(',') !=='')
        {
            solType=selectedSolutionCheckboxes.join(',');

        }

        console.log(API_URL+'getProductFilAll/'+appType+'/'+solType);

        if(val=='all')
        {
            
            try {
                setTimeout(async () => {
                const response = await axios.get(API_URL+'getProductFilAll/'+appType+'/'+solType);
                console.log('Response Data:', response.data); // Logging response.data
                setLoading(false); // Stop loading
                setModalIsOpen(false);
                setProductdata(response.data);
            }, 2000);
                
              } catch (error) {
                console.error('Error fetching data:', error);
                
              }

        }
        else
        {
            try {
                setTimeout(async () => {
                const response = await axios.get(API_URL+'getSingleProductDll/'+appType+'/'+solType+'/'+val);
                console.log('Response Data:', response.data); // Logging response.data
                setLoading(false); // Stop loading
                setModalIsOpen(false);
                setProductdata(response.data);
            }, 2000);
                
              } catch (error) {
                console.error('Error fetching data:', error);
                
              }

        }
        
        

    }


   
    const handleSolutionCheckboxChange =  (event) => {
       
        const checkboxId = event.target.value;
       
        setSelectedSolutionCheckboxes(prevSelectedCheckboxes => {
          if (event.target.checked) {
            
            return [...prevSelectedCheckboxes, checkboxId];
          } else {
            console.log(checkboxId);
            return prevSelectedCheckboxes.filter(id => id !== checkboxId);
          }
        });

       

      

    };

    const handleCheckboxChange =  (event) => {

       
        const checkboxId = event.target.value;
        console.log(checkboxId);
        
       
        setSelectedCheckboxes(prevSelectedCheckboxes => {
          if (event.target.checked) {
            
            return [...prevSelectedCheckboxes, checkboxId];
          } else {
            console.log(checkboxId);
            return prevSelectedCheckboxes.filter(id => id !== checkboxId);
          }
        });

        console.log(selectedCheckboxes.join(','));

        
        // Call the function to send data with updated 

       
         
      };



        

      const wait = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      };

  
   
    

    useEffect(() => {

        const fetchProductData = async () => {
            try {
              if(selectedCheckboxes.join(',')==='' && selectedSolutionCheckboxes.join(',')==='')
              {
                  setLoading(true); // Start loading
                  setModalIsOpen(true);
                  setTimeout(async () => {
                  const response = await axios.get(API_URL+'getProductAll');
                  //console.log('Response Data:', response.data); // Logging response.data
                  setLoading(false); // Stop loading
                  setModalIsOpen(false);
                  setProductdata(response.data);
                  setProductdataDLL(response.data);
              }, 2000);
    
              }
             
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };
        
     
        fetchProductData();

        const fetchProductSortData = async () => {
            try {
              if(selectedCheckboxes.join(',')==='' && selectedSolutionCheckboxes.join(',')==='')
              {
                  setLoading(true); // Start loading
                  setModalIsOpen(true);
                  
                  setTimeout(async () => {
                  const response = await axios.get(API_URL+'getProductSortAll/'+sort);
                  //console.log('Response Data:', response.data); // Logging response.data
                  setLoading(false); // Stop loading
                  setModalIsOpen(false);
                  setProductdata(response.data.itemsSort);
                  setProductdataDLL(response.data.itemsAll);
              }, 2000);
    
              }
             
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };


     

        const sendData = async (idsStringApp,idsStringSol) => {
          
            
        const MAX_RETRIES = 3;
        let retries = 0;

        console.log(sort);
      
        if(idsStringApp !=='' && idsStringSol==='')
        {
            setLoading(true); // Start loading
            setModalIsOpen(true);
            
       
            try {

                setTimeout(async () => {
                    const response = await axios.get(API_URL+'getProductFilterByApplication/'+idsStringApp+'/'+sort);
                console.log( response.data);
                setLoading(false); // Stop loading
                setModalIsOpen(false);

                setProductdata(response.data);
                  }, 2000);
            
                
            } catch (error) {
                
                if (error.response && error.response.status === 429) {
                    // Wait for 5 seconds before retrying
                    await wait(1000);
                    sendData(selectedCheckboxes.join(','));
                }
                
            }

         
        }


        else if(idsStringApp ==='' && idsStringSol !=='')
        {
            setLoading(true); // Start loading
            setModalIsOpen(true);
            
       
            
            try {

                setTimeout(async () => {
                    const response = await axios.get(API_URL+'getProductFilterBySolution/'+idsStringSol+'/'+sort);
                console.log( response.data);
                setLoading(false); // Stop loading
                setModalIsOpen(false);

                setProductdata(response.data);
                  }, 2000);
            
                
            } catch (error) {
                
                if (error.response && error.response.status === 429) {
                    // Wait for 5 seconds before retrying
                    await wait(1000);
                    sendData(selectedCheckboxes.join(','));
                }
                
            }

         
        }

        else if(idsStringApp !=='' && idsStringSol!=='')
        {
            setLoading(true); // Start loading
            setModalIsOpen(true);

          
         
            try {

                setTimeout(async () => {
                    const response = await axios.get(API_URL+'getProductFilterByApplicationSolution/'+idsStringApp+'/'+idsStringSol+'/'+sort);
                console.log( response.data);
                setLoading(false); // Stop loading
                setModalIsOpen(false);

                setProductdata(response.data);
                  }, 2000);
            
                
            } catch (error) {
                
                if (error.response && error.response.status === 429) {
                    // Wait for 5 seconds before retrying
                    await wait(1000);
                    sendData(selectedCheckboxes.join(','));
                }
                
            }

         
        }
        else{
            //console.log("QQQ");
            fetchProductSortData();

        }
        



        };

        sendData(selectedCheckboxes.join(','),selectedSolutionCheckboxes.join(','));



        
        const fetchProductApplicationData = async () => {
            try {
                setTimeout(async () => {
              const response = await axios.get(API_URL+'getProductGroupApplicationName');
              //console.log('Response Data:', response.data); // Logging response.data
              setProductApplicationdata(response.data);
            }, 2000);
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };
          fetchProductApplicationData();


          const fetchProductSolutionData = async () => {
            try {
                setTimeout(async () => {
              const response = await axios.get(API_URL+'getProductGroupSolution');
              //console.log('Response Data:', response.data); // Logging response.data
              setProductSolutiondata(response.data);
            }, 2000);
              
            } catch (error) {
              console.error('Error fetching data:', error);
              
            }
          };
          fetchProductSolutionData();

          //
         // 
         console.log(selectedCheckboxes+"======"+selectedSolutionCheckboxes);

       //Ends
      }, [selectedCheckboxes,selectedSolutionCheckboxes,retryAttempts]);

    
      //console.log(selectedCheckboxes);
     
     
      


    return (
        <>
        <Softbanner pageName="Product"/>
        <section className='produts_sec'>
            <Container>
                <Row>
                    <Col sm={4} lg={3}>
                        <div className="product_sidebar">
                            <Form>
                                <div className="filter_options">
                                    <div className="filter_title">Field Application</div>
                                    <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                shouldCloseOnOverlayClick={false} // Prevent close on overlay click
                style={customStyles}
                contentLabel="Loading Modal"
            >
                <h2>Loading...</h2>
            </Modal>
                                    <ul>
                                    { ProductApplicationdata && ProductApplicationdata.map((item, index) => (
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={item.product_application_uid} id={item.product_application_uid}    data-id={item.product_application_uid} onChange={(e) => handleCheckboxChange(e, selectedCheckboxes.join(','))}  />
                                                <label class="form-check-label" for={item.product_application_uid}>
                                                {item.product_application_name}
                                                </label>
                                            </div>
                                        </li>
                                         ))}
                                        
                                       
                                    </ul>
                                </div>
                                {/* <div className="filter_options mt-50">
                                    <div className="filter_title">Price Range</div>

                                    <div className={"values"}>Price: ${values[0]}- ${values[1]}</div>
                                    <Slider className={"slider"} onChange={setValues} value={values} min={MIN} max={MAX}/>
                                    <small className='pricetag'>Current Range: ${values[1] - values[0]}</small>
                                </div> */}
                                <div className="filter_options mt-50">
                                    <div className="filter_title">Solution Objectives</div>
                                    <ul>
                                    { ProductSolutiondata && ProductSolutiondata.map((item, index) => (
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={item.product_solution_object_uid} id={item.product_solution_object_uid} onChange={(e) => handleSolutionCheckboxChange(e, selectedSolutionCheckboxes.join(','))}  />
                                                <label class="form-check-label" for={item.product_solution_object_uid}>
                                                    {item.product_solution_object_name}
                                                </label>
                                            </div>
                                        </li>
                                         ))}
                                     
                                    </ul>
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={8} lg={9}>
                        <div className='productpage_header'>
                            <div className='filter_result'></div>
                            <ul className='sort_product'>
                                <li><p class="lan_tag">Filter By Name: </p></li>
                                <li><form>
                                    <select value={sort} onChange={handleChange} className='form-select'>
                                        <option value="all">All</option>
                                        { ProductdataDLL && ProductdataDLL.map((item, index) => (
                                        <option value={item.product_uid}>{item.product_name}</option>
                                        ))}
                                        
                                    </select>
                                    </form>
                                </li>
                            </ul>
                        </div>
                        <div className='Product_area'>
                            <Row>
                            { Productdata && Productdata.length > 0 ? ( Productdata.map((item, index) => (
                              <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        {/* <div className='producttag'>Most Popular</div> */}
                                        <Link to={'/product-details/'+item.product_slug}  className='product_img'>
                                            <img src={item.product_image_path} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>{item.product_name}</Link>
                                            {/* <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div> */}
                                            <div className='priceamount'
                                            >
                                                {/* <del>$30</del>  */}
                                            <span>${item.product_price}</span>
                                            <Link to={'/product-details/'+item.product_slug} className='btn btnprimary'>View Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col> 
                               ))
                               ) : (
                                   <div>No products available</div>
                               )}
                             
                                {/* <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product3} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product2} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <div className='producttag'>Most Popular</div>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product3} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <div className='product_item'>
                                        <Link to={"#"} className='product_img'>
                                            <img src={product1} alt='' className='img-fluid'/>
                                        </Link>
                                        <div className='product_info'>
                                            <Link to={"#"} className='productname'>Engine pistons and cog</Link>
                                            <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                                            <div className='priceamount'><del>$30</del> <span>$25</span></div>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default Products;
