import React, { useState, useRef ,useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import '../Footer/footer.css';
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaInternetExplorer } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import axios from 'axios';
import { API_URL } from '../../App';

const Footer = () => {

    const [Contactdata, setContactdata] = useState([]);
    const [ContactdataEmail, setContactdataEmail] = useState([]);

    const [ContactdataPhone, setContactdataPhone] = useState([]);

    useEffect(() => {
      
     
        fetchContactData();
        //console.log(ExpertTemdata);
    
       //Ends
      }, []);

      const fetchContactData = async () => {
        try {
          const response = await axios.get(API_URL+'getContactDetail');
          setContactdata(response.data);
           // Logging response.data
           var dt=response.data;
           var cid=dt[0].id;
           //console.log(cid);

           const response2 = await axios.get(API_URL+'getContactDetailEmail/'+cid);
           setContactdataEmail(response2.data);

           const response3 = await axios.get(API_URL+'getContactDetailPhone/'+cid);
           setContactdataPhone(response3.data);

          
          
          
         
        } catch (error) {
          console.error('Error fetching data:', error);
          
        }
      };

      const firstElement = Contactdata.length > 0 ? Contactdata[0] : null;
      const firstEmail = ContactdataEmail.length > 0 ? ContactdataEmail[0] : null;
      const firstPhone = ContactdataPhone.length > 0 ? ContactdataPhone[0] : null;

   
    return (
        <>
        <main className='footer_area'>
            <Container>
                <Row>
                    <Col sm={6} lg={3}>
                        <div className="f_brand">
                            <Link to={"#"} className='f_logo'><img src={logo} alt="" /></Link>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vitae tenetur, at atque placeat quae provident.</p>
                            <ul className="f-socialbar">
                                <li><Link to={"#"}><FaFacebookF/></Link></li>
                                <li><Link to={"#"}><FaLinkedinIn /></Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className="footer_content">
                            <b className='footer_title'>CONTACT INFO</b>

                            <ul className='contact_listing'>
                                <li>
                                    <div className="clisting_item">
                                        <div className="c_icon"><FaLocationDot /></div>
                                        <div className="c_info">{firstElement && (firstElement.contact_loaction)}</div>
                                    </div>
                                </li>
                                <li>
                                <div className="clisting_item">
                                        <div className="c_icon"><IoMail /></div>
                                        <div className="c_info"><Link to={"#"}> {firstPhone && (firstPhone.contact_phone)}</Link></div>
                                    </div>
                                </li>
                                <li>
                                <div className="clisting_item">
                                        <div className="c_icon"><FaInternetExplorer /></div>
                                        <div className="c_info"><Link to={"#"}> {firstEmail && (firstEmail.contact_email)}</Link></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className="footer_content">
                            <b className='footer_title'>OUR SERVICES</b>

                            <ul className='nav_listing'>
                                <li><Link to={"#"}><FaAngleRight /> Home</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> Services</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> Career</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> About</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> Team</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> Contact</Link></li>
                                <li><Link to={"#"}><FaAngleRight /> Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className="footer_content">
                            <b className='footer_title'>NEWSLETTER</b>
                            <p>Tetur adipiscing elit, sed do eiusmod tempor in cididunt ut labore.</p>
                            <form className='f-newsletter'>
                                <input type="text" className='form-control' placeholder='Your E-mail ID'/>
                                <button className='btn btnprimary'><span>Subscribe Now</span></button>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="copyright">
                <Container>
                    <Row>
                        <Col sm={12}><p>© 2024 Cortex.  Designed & Developed By <a href="https://elphilltechnology.com/" target="blank"> Elphill Technology Pvt Ltd</a></p></Col>
                    </Row>
                </Container>
            </div>
        </main>
        </>
    );
}

export default Footer;
