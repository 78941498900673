import React from 'react';
import softbg from '../../images/softbanner-bg.jpg';
import '../softbanner/softbanner.css'
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Softbanner = (props) => {
    return (
        <>
        <main className='softbanner'>
            <img src={softbg} alt="" className='img-fluid' />
            <div className="softbanner_wrapper">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h2>{props.pageName}</h2>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{props.pageName}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </div>
        </main>
        </>
    );
}

export default Softbanner;
