import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../teamcards/teamcard.css'

const Teamcard = (props) => {
    return (
        <>
        <Col sm={4} md={3}>
            <div className="teamcard_item">
                <Link to="#" className="team_img">
                    <img src={props.teamImg} alt="" />
                </Link>
                <div className="team_content">
                    <h6><Link to="#">{props.teamName}</Link></h6>
                    <p>{props.teamDsg}</p>
                </div>
            </div>
        </Col>
        </>
    );
}

export default Teamcard;
